import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import GlobalTop from "../components/globalTop"
import ContactBanner from "../components/contactBanner"
import { Link } from "gatsby"

const AboutUs = () => (
  <Layout>
    <SEO title="O nas" />
    <GlobalTop title="O nas" />
    <section className="about-content">
      <div className="container">
        <h2 className="section--header text-center">
          Poznaj <strong>nas</strong>
        </h2>
        <div className="row align-items-center">
          <div className="col-md-6">
            <p>
              <strong>
                Historia <Link to="/medika-1/">naszej firmy</Link> jest
                niezwykłą podróżą pełną sukcesów i rozwoju, która w ciągu lat
                przekształciła się w pionierską placówkę medyczną.
              </strong>{" "}
              Początki były skromne, ale moje zaangażowanie, pasja i wizja
              przyciągnęły do mnie profesjonalistów z branży, którzy podzielali
              moje cele i pragnienie zmieniania życia pacjentów.
              <br />
              <br />
              Rozpoczęło się od jednego gabinetu podologicznego,w którym jako
              specjalista, poświęcałam swoje dni starając się świadczyć jak
              najlepszą opiekę podologiczną. Moje oddanie wobec pacjentów
              niezmiennie było i jest najważniejsze, a moja empatia i
              zaangażowanie szybko owocowało rzeszą stałych, ale też i nowych
              pacjentów.
            </p>
          </div>
          <div className="col-md-6 text-center">
            <img
              className="img-fluid"
              src={require("../assets/images/about-image-1.png")}
              alt=""
            />
          </div>
        </div>
        <div className="row align-items-center">
          <div className="col-md-6 text-center">
            <img
              className="img-fluid"
              src={require("../assets/images/about-image-2.png")}
              alt=""
            />
          </div>
          <div className="col-md-6">
            <p>
              Sukcesywnie rozwijałam swoją praktykę, angażując do zespołu
              wykwalifikowanych specjalistów, których wartości i profesjonalizm
              zgadzały się z moim. Zaczęłam współpracę z Mateuszem Jeziorskim
              znakomitym podologiem, specjalistą nie tylko z wykształcenia, ale
              i z powołania. Pasja do podologii sprawiła, że Mateusz ukończył
              wiele specjalistycznych szkoleń podologicznych i nadal poszerza
              swoją wiedzę, wierząc, że pewnie stojąc na silnych nogach można
              odważnie stawiać czoła codzienności. Teraz stał się moim
              wspólnikiem i razem wspieramy się w budowaniu firmy Podologia s.c.
              <br />
              <br />Z czasem, rosnąca liczba pacjentów i ich różnorodne potrzeby
              skłoniły mnie do poszerzenia zakresu usług medycznych, oferując
              kompleksową opiekę. Spotkałam na swej drodze cudowną{" "}
              <strong>specjalistkę i lekarza Anię Płotek</strong> tworzymy
              wspólnie spółkę o nazwie <Link to="/medika-1/">Medika 1</Link>.
              Zaczęłyśmy wprowadzać innowacyjne technologie i procedury, które
              znacząco poprawiają jakość diagnostyki i leczenia. Nasz zespół
              stale podnosi swoje kwalifikacje, uczestnicząc w szkoleniach i
              konferencjach, aby być na bieżąco z najnowszymi osiągnięciami
              medycznymi.
            </p>
          </div>
        </div>
        <div className="row align-items-center">
          <div className="col-md-6">
            <p>
              Wraz z rozwojem zrodziła się w nas wizja większej misji.
              Zauważyłyśmy luki i niedociągnięcia w systemie opieki zdrowotnej,
              które wpływają na jakość życia pacjentów.{" "}
              <strong>
                Postanowiłyśmy zaryzykować i przekształcić nasza firmę w firmę
                medyczną
              </strong>{" "}
              o większym zasięgu, która wprowadza innowacyjne rozwiązaniaw
              zakresie opieki zdrowotnej. Zajmujemy się diagnostyką chorób
              naczyniowych, opieką pielęgniarską, leczeniem ran przewlekłych,
              fizjoterapią ran i blizn. Wraz z Mateuszem i Robertem cały czas
              rozwijamy podologie i chirurgię palca w firmie Podologia sc w
              synergii pracując z Ania Płotek lekarzem, Justyna Janisz
              wykwalifikowaną pielęgniarką i Patrycją Skrzypek -
              fizjoterapeutką.
              <br />
              <br />
              Przebudowa Naszej placówki wiąże się z wieloma wyzwaniami.
              Tworzymy partnerstwa strategiczne, nawiązujemy kontaktyz
              ekspertami i przyciągamy inwestorów, którzy podzielają naszą
              wizję. Wprowadzamy zmiany organizacyjne i technologiczne, aby
              zapewnić płynne funkcjonowanie placówki medycznej na coraz większa
              skale. Nad organizacja pracy specjalistów czuwa nasz niezawodny
              manager medyczny.
            </p>
          </div>
          <div className="col-md-6 text-center">
            <img
              className="img-fluid"
              src={require("../assets/images/about-image-3.png")}
              alt=""
            />
          </div>
        </div>
        <div className="row align-items-center">
          <div className="col-md-6 text-center">
            <img
              className="img-fluid"
              src={require("../assets/images/about-image-4.png")}
              alt=""
            />
          </div>
          <div className="col-md-6">
            <p>
              <strong>
                Nasza firma medyczna, której historia jest pełna determinacji i
                zaangażowania, teraz stanowi centrum doskonałości medycznej.
              </strong>{" "}
              Wprowadzamy innowacje w zakresie leczenia i diagnostyki,chorób
              naczyń, stopy cukrzycowej i ran nowotworowych wykorzystując
              analizę danych ,nowoczesne narzędzia i sztuczną inteligencję.
              Chciałybyśmy mieć realny wpływ na branżę medyczną, dostarczając
              unikalnych rozwiązań, które poprawiają jakość życia pacjentów na
              całym świecie.
              <br />
              <strong>
                Naszym marzeniem jest aby firma przekształciła się w globalne
                przedsiębiorstwo medyczne, mające znaczący wpływ na opiekę
                zdrowotną i zdrowie publiczne.
              </strong>{" "}
              Nie zapominamy jednak o swoich korzeniach i wciąż kierujemy się
              pierwotną misją, by pomagać pacjentom i poprawiać jakość ich
              życia.
              <br />
              <br />
              Nasza historia jest inspirującym przykładem, jak determinacja,
              pasja i wizja mogą przekształcić małą praktykę w prężnie
              działającą placówkę medyczną. Jest dowodem na to, że zaangażowanie
              w dobro pacjentów i nieustanne dążenie do doskonałości prowadzą do
              sukcesu.{" "}
              <strong>
                Pozwala dalej marzyć o lepszej przyszłości i ciągłym rozwoju.
              </strong>
            </p>
          </div>
        </div>
        <ContactBanner />
      </div>
    </section>
  </Layout>
)

export default AboutUs
