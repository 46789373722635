import React from "react"
import { Link } from "gatsby"

const ContactBanner = () => {
  return (
    <div className="contact-banner">
      <h1>
        Skontaktuj <strong>się z nami</strong>
      </h1>
      <p>
        Zapoznaj się z naszym zespołem specjalistów.
        <br />
        Nasza wiedza i doświadczenie mogą poprawić Twoje życie już dziś.{" "}
      </p>
      <Link to="/nasi-specjalisci/" className="btn-custom btn-custom--purple">
        Nasi specjaliści
      </Link>
    </div>
  )
}

export default ContactBanner
